<template>

	  <!-- Navigation -->
	<nav class="navbar navbar-default navbar-fixed-top">

		<div class="container">
			<!-- Brand and toggle get grouped for better mobile display -->
			<div class="nav-group">
				<div class="brand">
					<a class="raw" href="index.html">
						<img class="logo" src="../../../public/img/ChipSLogo_schwarz.png" alt="IEEE 5G++ Summit">
					</a>
				</div>
				<div class="navbar-header page-scroll">
					<button class="navbar-toggle" data-target="#bs-example-navbar-collapse-1" data-toggle="collapse" type="button">
						<i class="fa fa-bars" aria-hidden="true" style="color:lightgray;"></i><span class="sr-only">Toggle navigation</span>
					</button>
					<a class="raw brand" href="index.html">
						<img class="logo" src="../../../public/img/ChipSLogo_schwarz.png" alt="IEEE 5G++ Summit">
					</a>
				</div>
				<!-- Collect the nav links, forms, and other content for toggling -->
				<div class="collapse navbar-collapse" aria-expanded="false" id="bs-example-navbar-collapse-1">
				<ul class="nav navbar-nav navbar-right">
					<li class="hidden"></li>
					<li v-for="item of visibleItems" class="page-scroll" :key="item.text">
						<a :href="'#' + getArgument(item.target)" v-html="getArgument(item.text)"></a>
					</li>
				</ul>
			</div>

			</div>

			<!-- /.navbar-collapse -->
		</div>


	</nav>

</template>

<script>

	export default {
		name: 'mainNav',
		props: {
			menu: Array,
			getArgument: {
				type: Function,
				default: (a) => {
					if ( a === undefined ) return null;
					if ( typeof a == "object") return a.value
					return a
				}
			},
			dispNow: {
				type: Function,
				default: () => true
			}
		},
		computed: {
			visibleItems: function(){
				let res = [];
				for (let item of this.menu) if (this.dispNow(item)) res.push(item);
				return res;
			}
		}
	}

</script>

<style lang="less">

@import '../../style-variables.less';

img.logo{
	border: none;
	width: @logo-width-small;
	margin-top: @logo-margin;
}

.navbar{
	position: sticky;
	// top: 0;
	z-index: 10;
	margin: 0;
	background: linear-gradient(#30668b,#3c779f);
}

.navbar-fixed-top .navbar-collapse {
	max-height: 100vh;
}


nav a:active{
	color: unset;
}
nav a, nav a:focus{
	color: white !important;
}
nav a {
	transition: 0.2s ease-in-out;
}

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
	// color: @link-light !important;
	color: #26CEB2 !important;
}

.navbar-wide {
	display: none;
}
.navbar-narrow {
	display: block !important;
}
.nav-group > .brand {
	display: none;
}
.page-scroll > .brand {
	display: block;
	margin: 5px;
}

@media(min-width:@layoutThreshold) {
    img.logo{
		border: none;
		width: @logo-width;
	}
	.nav-group > .brand {
		display: block;
	}
	.page-scroll > .brand {
		display: none;
	}
	.nav-group {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.navbar-fixed-top {
        padding: 10px 0;
        -webkit-transition: padding .3s;
        -moz-transition: padding .3s;
        transition: padding .3s;
    }
    .navbar-fixed-top .navbar-brand {
        font-size: 2em;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    }
    .navbar-fixed-top.navbar-shrink {
        padding: 5px 0;
		ul {
			padding-top: 5px;
			li.page-scroll a{
				line-height: 10px !important;
			}
		}
	}


    .navbar-fixed-top.navbar-shrink .navbar-brand {
        font-size: 1.5em;
    }
}



.navbar-default {
	background-color: @navbar !important;
	box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.3);
}

.navbar {
    /* text-transform: uppercase; */
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
}


.navbar-toggle{
	transition: all 0.2s ease-out;
}

.navbar-collapse{
	@media (max-width: 1199px) {
		border-top: 2px solid white !important;
	}
}


.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	background-color: #1a242f;
}


.navbar a:focus {
    outline: 0;
}


.navbar .navbar-nav {
    letter-spacing: 1px;
}

.navbar .navbar-nav li a:focus {
    outline: 0;
}

.navbar-default,
.navbar-inverse {
    border: 0;
}


</style>
