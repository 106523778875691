<template>
	<div>
		<div @click="openModal" style="cursor: pointer;">
			<img v-if="data && !data.src2x" :src="picfolder+data.src" :alt="name" title="Click for more information" :style="exhibitorStyles(data)"/>
			<img v-else-if="data && data.src2x" :src="picfolder+data.src" :srcset="picfolder + data.src + ' 1x,' + picfolder + data.src2x + ' 2x'"  :alt="name" title="Click for more information" :style="exhibitorStyles(data)"/>
			<div v-else class="logo-placeholder" title="click for more information">Logo "{{ name }}" missing</div>
		</div>
		<modal ref="modal" :title="'Exhibitor: ' + name">
			<div style="display: flex; flex-direction: row; justify-items: flex-start; width: 100%; padding: 1em; gap: 1em;">
				<div style="flex: 0 0 auto;">
					<img :src="picfolder+data.src" :alt="name" :style="exhibitorStyles(data)"/>
				</div>
				<div style="flex: 1 1 auto; display: flex; flex-direction: column; justify-items: flex-start; gap: 1em;">
					<div style="min-height: 10em">
						<slot/>
					</div>
					<div style="display: flex; justify-content: space-between; font-size: 1.2em; font-weight: bold;">
						<div>
							<a :href="data.URL" target="_blank" class="website" title="Visit website">
								<i class="fa fa-external-link" aria-hidden="true"></i>
								<span>Visit website</span>
							</a>
						</div>
						<div>
							Booth: {{ number }}
						</div>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>

	import modal from '../core/components/generalmodal.vue'



	export default {
		components: {
			modal: modal,
		},
		props: {
			name: String,
			number: String,

		},
		data: function(){
			return {
				data: undefined,
				showmodal: false,
				picfolder: "/data/sponsors/",
			};
		},
		computed: {},
		created: function(){
			this.reloadJSON();
		},
		methods: {
			openModal: function (speaker) {
				this.$refs.modal.openModal();
			},

			reloadJSON: async function () {
				let self = this
				let url = "/data/sponsors.json"
				let response = await fetch( url )
				let data = await response.json()

				if ("Exhibitors" in data){
					for (let exhibitor of data.Exhibitors){
						if (exhibitor.Name.toLowerCase() == self.name.toLowerCase()){
							self.$set(self,'data',exhibitor)
							break;
						}
					}
				}

			},

			exhibitorStyles: function (exhibitor) {
				var res = {};
				res["width"] = exhibitor.width + "px";
				res["max-width"] =
				"calc(100% - " +
				(parseInt(exhibitor.marginLeft ? exhibitor.marginLeft : 20) +
				parseInt(exhibitor.marginRight ? exhibitor.marginRight : 20)) +
				"px)";
				if (exhibitor.marginLeft)
				res["margin-left"] = exhibitor.marginLeft + "px";
				if (exhibitor.marginRight)
				res["margin-right"] = exhibitor.marginRight + "px";
				if (exhibitor.marginTop) res["margin-top"] = exhibitor.marginTop + "px";
				if (exhibitor.marginBottom)
				res["margin-bottom"] = exhibitor.marginBottom + "px";
				return res;
			},

		},
	}

</script>

<style lang="less">

</style>
