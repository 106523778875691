<template>
	<!-- Speakers -->
	<div>
		<div class="container">
			<div class="row text-center">
				<p v-show="speakers.length==0">To be announced.</p>
				<div v-show="speakers.length>0" class="center-content">
					<div class="form-group has-feedback has-search">
						<span aria-hidden="true" class="fa fa-search form-control-feedback"></span>
						<input id="search-speakers" @keyup.escape="searchSpeakers=''" v-model.trim="searchSpeakers"
						type="text" class="form-control input search" placeholder="Search">
					</div>
				</div>
				<div class="flex-grid text-center">
					<div class="committee-thumb speaker" v-for="(member,index) in visibleSpeakers" :key="member.name+member.affiliation">
						<template>
							<div v-if="showcmscontrols"
								class="on-page-cms speaker-ctrl" @click.stop.prevent="cmsEditSpeaker(member)"><i
								class="fa fa-edit" aria-hidden="true"></i>
							</div>
							<div v-show="member.bio" class="image-container" style="cursor: pointer;" @click="openModal(member)">
								<img :src="speakerPicture[index]" :alt="member.name" />
								<div class="committee-middle">
									<div class="committee-text">
										Click for<br/>Details
									</div>
								</div>
							</div>

							<span v-if="!member.bio">
								<img :src="speakerPicture[index]" :alt="member.name" style="cursor: auto" />
							</span>
							<div>
								<a @click="openModal(member)" style="cursor: pointer; font-size: 1.2em;" v-show="member.bio" class="raw">{{member.name}}</a>
								<span v-show="!member.bio">{{member.name}}</span>

								<div style="font-size:1.2em">
									<div style="margin-top: 0.3em;">{{member.affiliation}}</div>
									<div style="margin-top: 0em; opacity: 0.6">{{member.position}}</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>

		<speakermodal :picturespath="pictures" ref="speakermodal"></speakermodal>

	</div>

</template>

<script>

	import speakermodal from  './speakermodal.vue';

	export default {
		name: 'speakers',
		props: {
			showcmscontrols: Boolean,
			json: String,
			pictures: String,
			nopicpic: {
				type: String,
				default: "img/ph.jpg",
			},
			cachebreakers: {
				type: Boolean,
				default: false
			}
		},
		components: {
			speakermodal: speakermodal,
		},
		data: function(){
			return {
				speakers: [],
				searchSpeakers: "",
			};
		},
		created: function(){
			this.reloadJSON();
		},
		computed: {
			visibleSpeakers: function(){
				let res = [];
				for (let s of this.speakers) {
					if (!this.searchSpeakers) res.push(s);
					else if ( (s.name + " " + s.affiliation + " " + s.position).toLowerCase().includes(this.searchSpeakers.toLowerCase()) )	res.push(s);
				}
				return res;
			},
			speakerPicture: function () {
				let res = [];
				for (let s of this.visibleSpeakers) {
					if (!s.portrait.trim()) res.push(this.nopicpic);
					else res.push(this.pictures + s.portrait);
				}
				return res;
			},
		},
		methods: {
			reloadJSON: async function () {
				let self = this

				console.log("reloading Speakers");
				let response = await fetch(self.json + "?" + Date.now())
				let data = await response.json()

				self.addData("speakers")(data);
			},
			addData: function (field) {
				let self = this;
				return function (data) {
					if (self.cachebreakers) {
						for (let s of data) {
							if (s.portrait != "ph.jpg") {
								s.portrait = s.portrait + "?" + Date.now();
							}
						}
					}
					self.$set(self,'speakers',data);
				};
			},
			openModal: function(speaker){
				this.$refs["speakermodal"].openModal(speaker);
			},
			cmsEditSpeaker: function (speaker) {
				this.$emit("editspeaker",speaker)
			},
		},
	}

</script>

<style lang="less">

.image-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 180px;
	margin-bottom: 1em;
	.committee-middle{
		position: block;
		left: unset;
		top: unset;
		transform: unset;
		// transition: unset;
		opacity: 0;
	}
}

.speaker.committee-thumb img {
	transition: 0.3s all ease-out;
	border-radius: 50%; // <- Round Pics
	max-width: 90%;
	position: absolute;
}


.speaker.committee-thumb .image-container:hover img {
	opacity: 0.5;
}


.on-page-cms{

	&.speaker-ctrl{
		font-size: 2rem;
		line-height: 1;
		padding: 5px;
		padding-left: 7px;
		padding-top: 7px;
		border-radius: 50%;
		cursor: pointer;
		display: inline-block;
		&:hover{
			color: black;
			background-color: desaturate(orange,5%);
		}
	}


	&.speaker-ctrl{
		position: absolute;
		background-color: fade(desaturate(orange,5%),20%);
		top: -2px;
		left: -2px;
		border: 1px solid lightgray;
		color: #eee;
	}
}


</style>
