


import chat from  './components/chat.vue';
import cosponsors from  './components/cosponsors.vue';
import nownext from  './components/nowNextUp.vue';
import sentry from  './components/sentry.vue';
import schedulesearch from  './components/schedule-search.vue';
import HeaderAnimation from './components/HeaderAnimation.vue';
import exhibitor from './components/exhibitor.vue';

//import videomodal from './components/videomodal.vue';

import faruler from './core/components/fa-ruler.vue';
import sponsors from  './core/components/sponsors.vue';
import generalmodal from './core/components/generalmodal.vue';

import { config } from './config.js';

export var components = {
	chat: chat,
	cosponsors: cosponsors,
	nownext: nownext,
	sentry: sentry,
	schedulesearch: schedulesearch,
	//videomodal,
	faruler,
	sponsors,
	generalmodal,
	HeaderAnimation,
	exhibitor
	//countdown
}

// This data is globall available as l.key
export var logicData = {
	showLocalTimes: false,
	includeVat: false,
}

export var logicMixin = {
	name: "userLogic",
	watch: {
	},
	computed: {

	},
	methods: {
		registerEvents: function(){

		},
		getPrice: function(price,vat){
			if ( ! vat ) vat = 19

			if ( ! this.l.includeVat ) return price
			else {
				let p = Math.round( price * ( 1 + vat/100 ) * 100 ) / 100
				p = p + ""
				if ( p.match( /^\d+$/) ) p += ".00"
				if ( p.match( /^\d+\.\d$/) ) p += "0"
				return p
			}
		},

		time: function( t, defaultStr ){

			// let match = t.match(/(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d)(:\d\d)?/)
			// //if ( ! match ) return "Error"

			// let year = match[1]
			// let month = match[2]
			// let day = match[3]
			// let hour = match[4]
			// let minute = match[5]
			// let second = 0

			// let factor
			// match = config.utcoffset.match(/(\+|-)(\d\d)(\d\d)/)
			// if ( match[0] == "+" ) factor = -1
			// if ( match[0] == "-" ) factor = 1

			if ( this.l.showLocalTimes ){

				let d = new Date( t + config.utcoffset );
				return d.toLocaleString([],{ month: "short", day: "numeric", hour: "numeric", minute: "numeric"});
			}else{
				if ( defaultStr ) return defaultStr
				let d = new Date( t )
				let hour = d.getHours();
				let minute = d.getMinutes() + "";
				if ( minute.length == 1 ) minute = "0" + minute;
				return hour + ":" + minute
			}
		}

	}
}



export var hooks = {
	mounted: function(){
		// console.log("First Mount");
	},
	created: function(){

	},
	beforeTimeUpdate: function(){
		// console.log("Before Time Update",this.time);
	},
	afterTimeUpdate: function(){
		// console.log("After Time Update",this.time);
	},
	relativeToTimeUpdate: [
		// {
		// 	offset: -1000,
		// 	fn: function() {console.debug("hier komme ich auch.", this.g.time);}
		// },
	],

	beforeContentReload: function(){

	},

	afterContentReload: function(){
		//
	}
}
