


export var config = {

	utcoffset: "+0200",
	testLocations: ['localhost','5gsummit.org/dresden123'],
	startTime: Date.now(),
	intervalUpdateTimeLogicS: 10,
	intervalReloadContentsMin: 30,
	limitedCountryCodes: ["CN","IR","KP","CU","ID","SY","SD"],
	faRulerSymbol: 'fa fa-star',

	// passwordHash: "37f99c98160b991b9a81925f8df132ca",
	// passwordCookieExpirationDays: 30,
	// currentScheduleItemLinksTo: "#about",

	gotoStartButtonTime: "10.5.2025, 8:00:00",
}
